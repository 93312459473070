import { createI18n } from "vue-i18n";
import de from "./assets/locales/de.json";
import en from "./assets/locales/en.json";

function getStartingLocale() {
  if (localStorage.getItem("last-locale")) {
    return localStorage.getItem("last-locale");
  }
  return "en";
}

const i18n = createI18n({
  locale: getStartingLocale(),
  messages: {
    de,
    en,
  },
});

const $t = i18n.global.t;

export { i18n, $t };
