<template>
  <div class="p-5 mb-5 border rounded-md border-slate-200/60 dark:border-darkmode-400">
    <div
      class="flex items-center text-base font-medium cursor-pointer"
      :class="isOpen ? 'border-slate-200/60 dark:border-darkmode-400  border-b pb-5' : ''"
      @click="isOpen = !isOpen"
    >
      <ChevronDownIcon class="w-4 h-4 mr-2" /> {{ props.title }}
    </div>
    <div v-if="isOpen">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
});

const isOpen = ref(false);
</script>
