<template>
  <div>
    <div v-if="authStore.loading" class="grid h-screen place-items-center">
      <div class="h-32">
        <LoadingIcon icon="rings" color="white"></LoadingIcon>
      </div>
    </div>
    <div v-else :class="authStore.user">
      <CAlert />
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/auth-store";
import CAlert from "@/components/alert/Main.vue";

const authStore = useAuthStore();
authStore.load();
</script>
