<script setup>
import { storeToRefs } from "pinia";

import { useAlertStore } from "@/stores/alert-store";

const alertStore = useAlertStore();
const { alert } = storeToRefs(alertStore);
</script>

<template>
  <div v-if="alert" class="container fixed z-50">
    <Alert class="flex items-center mb-2 alert-danger" v-slot="{ dismiss }">
      <AlertOctagonIcon class="w-6 h-6 mr-2" /> {{ alert.message }}
      <button
        type="button"
        class="text-white btn-close"
        aria-label="Close"
        @click="dismiss"
      >
        <XIcon class="w-4 h-4" />
      </button>
    </Alert>
  </div>
</template>
