<template>
  <div
    class="p-10 bg-white"
    v-if="
      !authStore.user?.Role.Permissions.some((e) => e.key == 'edit_all') &&
      props.lang != '' &&
      authStore.user?.lang != props.lang
    "
    v-html="modelValue"
  ></div>
  <div v-else>
    <div class="relative">
      <component
        :is="tagName"
        ref="editorRef"
        v-editor-directive="{ props, emit, cacheData }"
        class="select"
      ></component>
      <!-- Tooltip Container -->
      <div
        class="absolute flex flex-col items-center bottom-2 right-2 group"
        @click="toggleModal"
      >
        <ZapIcon
          aria-label="Your Icon Description"
          class="w-10 h-10 p-2 text-purple-500 transition-colors duration-150 ease-in-out rounded-lg cursor-pointer hover:bg-purple-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 active:scale-95"
        ></ZapIcon>
        <!-- Tooltip Text -->
        <span
          class="absolute hidden w-auto p-2 mb-2 text-xs text-white bg-black rounded-md shadow-lg bottom-full group-hover:block"
        >
          {{ $t("ki-custom-prompt") }}
        </span>
      </div>
    </div>

    <Modal :show="isModalOpen" @hidden="isModalOpen = false">
      <ModalBody>
        <div class="p-5">
          <h3 class="text-lg">{{ $t("customPrompt") }}</h3>
          <textarea
            v-model="customPrompt"
            class="w-full p-2 mt-2 border rounded"
          ></textarea>

          <div class="mt-4">
            <h4 class="text-md">{{ $t("defaultPrompts") }}</h4>
            <select
              v-model="customPrompt"
              class="w-full p-2 mt-2 border rounded"
            >
              <option value="">{{ $t("selectPrompt") }}</option>
              <option
                v-for="prompt in defaultPrompts"
                :key="prompt"
                :value="prompt"
              >
                {{ prompt }}
              </option>
            </select>
          </div>

          <h3 class="mt-4 text-lg">{{ $t("aiResponse") }}</h3>
          <div class="w-9 h-9" v-if="loading">
            <LoadingIcon icon="rings" class="text-primary"></LoadingIcon>
          </div>
          <div
            v-if="!loading && aiResponse != ''"
            class="mt-4 prose dark:prose-invert prose-img:rounded-xl prose-headings:text-lg prose-headings:font-medium prose-a:text-primary prose-h1:text-xl prose-strong:text-md max-w-none prose-strong:text-gray-600 prose-em:text-gray-600 prose-p:text-gray-600"
            v-html="aiResponse"
          ></div>

          <div class="mt-4" v-if="error">
            <span class="text-danger"
              >Please check your custom prompt. It should contain {input}</span
            >
          </div>

          <div class="flex justify-between mt-6">
            <button
              :disabled="loading"
              @click="handleSubmit"
              class="btn btn-primary"
            >
              {{ $t("sendToKi") }}
            </button>
            <button
              :disabled="loading || aiResponse == ''"
              @click="handleReplaceText"
              class="btn btn-primary"
            >
              {{ $t("replaceText") }}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/auth-store";
import { inject, onMounted, ref } from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { init } from "./index";
import kiService from "@/services/ki";

const authStore = useAuthStore();
const isModalOpen = ref(false);
const loading = ref(false);
const error = ref(false);
const customPrompt = ref("");
const aiResponse = ref("");
const defaultPrompts = ref([
  "Improve the following text for our Dog Training App. Only provide the result text: \n\n {input}",
]);

const toggleModal = () => {
  isModalOpen.value = !isModalOpen.value;
};

const handleSubmit = async () => {
  error.value = false;

  if (customPrompt.value == "" || !customPrompt.value.includes("{input}")) {
    error.value = true;
    return;
  }

  loading.value = true;
  const prompt = customPrompt.value;

  const response = await kiService.customPrompt(
    prompt,
    editorRef.value.CKEditor.getData()
  );

  aiResponse.value = response.content;
  loading.value = false;
};

const handleReplaceText = () => {
  editorRef.value.CKEditor.setData(aiResponse.value);

  toggleModal();
};

const vEditorDirective = {
  mounted(el, { value }) {
    init(el, ClassicEditor, value);
  },
};

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  lang: {
    type: String,
    required: true,
  },
  config: {
    type: Object,
    default: () => ({}),
  },
  tagName: {
    type: String,
    default: "div",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  refKey: {
    type: String,
    default: null,
  },
});

const emit = defineEmits();

const editorRef = ref();
const cacheData = ref("");

const bindInstance = () => {
  if (props.refKey) {
    const bind = inject(`bind[${props.refKey}]`);
    if (bind) {
      bind(editorRef.value);
    }
  }
};

onMounted(() => {
  bindInstance();
});
</script>
