import { defineStore } from "pinia";
import auth from "@/services/auth";
import router from "@/router";
import { useAlertStore } from "@/stores/alert-store";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: {},
    returnUrl: null,
    loading: false,
  }),
  actions: {
    async load() {
      this.loading = true;
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        this.user = user;
      }
      this.loading = false;
    },
    async login(username, password) {
      try {
        const user = await auth.login(username, password);

        // update pinia state
        this.user = user.data;

        // store user details and jwt in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user.data));

        // redirect to previous url or default to home page
        router.push(this.returnUrl || "/");
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error.response.data.message);
      }
    },
    logout() {
      router.push("/login");
      auth.logout();
      this.user = null;
      localStorage.removeItem("user");
    },
  },
});
