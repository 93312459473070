import main_url from "./glob.js";
const url = main_url + "/api/ai-chat/";

var kiService = {
  async getAll(page) {
    const res = await fetch(url + "conversations/all/" + page, {
      method: "GET",
      credentials: "include",
    });

    const status = res.status;
    if (status == 200) {
      return res.json();
    } else {
      console.log("no auth");
    }
  },
  async getUserConversations(id) {
    const rest = await fetch(url + "conversations/" + id, {
      method: "GET",
      credentials: "include",
    });

    const status = rest.status;
    if (status == 200) {
      return rest.json();
    } else {
      console.log("no auth");
    }
  },
  async createConversation(id) {
    const rest = await fetch(url + "conversations/" + id, {
      method: "POST",
      credentials: "include",
    });

    const status = rest.status;
    if (status == 200) {
      return rest.json();
    } else {
      console.log("no auth");
    }
  },
  async createMessage(conversation_id, input) {
    const rest = await fetch(
      url + "conversations/" + conversation_id + "/messages",
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          input: input,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const status = rest.status;
    if (status == 200) {
      return rest.json();
    } else {
      console.log("no auth");
    }
  },
  async customPrompt(prompt, input) {
    const rest = await fetch(url + "custom_prompts/messages", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        prompt: prompt,
        input: input,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const status = rest.status;
    if (status == 200) {
      return rest.json();
    } else {
      console.log("no auth");
    }
  },
  async getScores() {
    const rest = await fetch(url + "scores", {
      method: "GET",
      credentials: "include",
    });

    const status = rest.status;
    if (status == 200) {
      return rest.json();
    } else {
      console.log("no auth");
    }
  },
  async voteConversation(conversation_id, score) {
    const rest = await fetch(url + "scores/" + conversation_id, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        score: score,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const status = rest.status;
    if (status == 200) {
      return rest.json();
    } else {
      console.log("no auth");
    }
  },
  async searchSimilar(input) {
    const rest = await fetch(url + "searchs/search", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        input: input,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const status = rest.status;
    if (status == 200) {
      return rest.json();
    } else {
      console.log("no auth");
    }
  },

  async getStartingMessages() {
    const rest = await fetch(url + "starting_messages/all", {
      method: "GET",
      credentials: "include",
    });

    const status = rest.status;
    if (status == 200) {
      return rest.json();
    } else {
      console.log("no auth");
    }
  },

  async getStartingMessage(id) {
    const rest = await fetch(url + "starting_messages/" + id, {
      method: "GET",
      credentials: "include",
    });

    const status = rest.status;
    if (status == 200) {
      return rest.json();
    } else {
      console.log("no auth");
    }
  },

  async updateStartingMessage(id, language_code, message) {
    const rest = await fetch(url + "starting_messages/messages", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        language_code: language_code,
        message: message,
        id: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const status = rest.status;
    if (status == 200) {
      return rest.json();
    } else {
      console.log("no auth");
    }
  }
};

export default kiService;
