<template>
  <div class="col-span-12 intro-y box">
    <div class="grid grid-cols-6 gap-6">
      <div class="p-5">
        <div @click="copyURL()" class="w-32 mb-2 mr-2 btn btn-primary">DeepLink</div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
});

function copyURL() {
  try {
    navigator.clipboard.writeText(props.url).then(() => {
      alert("Copied");
    });
  } catch ($e) {
    alert("Cannot copy");
  }
}
</script>
