import axios from "axios";
import router from "@/router";
import main_url from "./glob.js"

const url = main_url + "/api/";

var auth = {
  async login(email, password) {
    var raw = {
      email: email,
      password: password,
    };

    const res = await axios.post(url + "login", raw, { withCredentials: true });

    const status = res.status;
    if (status == 200) {
      return res.data
    } else {
      console.log("no auth");
    }
  },
  async verify() {
    const res = await axios
      .get(url + "verify", { withCredentials: true })
      .catch(function (error) {
        return false;
      });

    const status = res.status;
    return status == 200;
  },
  async logout() {
    const res = await axios.post(url + "logout", null, {
      withCredentials: true,
    });
    router.push("/login");
  },
  async user() {
    const res = await axios.get(url + "user", { withCredentials: true });
    return res.data;
  },
  async roles() {
    const res = await fetch(url + "roles", {
      method: "GET",
      credentials: "include",
    });

    const status = res.status;
    if (status == 200) {
      return res.json();
    } else {
      console.log("no auth");
    }
    
  },
  async users() {
    const res = await axios.get(url + "users", { withCredentials: true });
    return res.data;
  },
  async delete(id) {
    const res = await axios.get(url + "delete/" + id, {
      withCredentials: true,
    });
    return res.data;
  },
  async register(email, first_name, last_name, password, profile_picture,language, role  ) {
    var raw = {
      "email": email,
      "password": password,
      "first_name": first_name,
      "last_name": last_name,
      "profile_picture": profile_picture,
      "role": role,
      "lang": language,
    };

    const res = await fetch(url + "register", {
      method: "POST",
      body: JSON.stringify(raw),
      credentials: "include",
    });

    const status = res.status;
    if (status == 200) {
      return true
    } else {
      return false;
    }
  },
};

export default auth;
