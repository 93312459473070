import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import globalComponents from "./global-components";
import utils from "./utils";
import "./assets/css/app.css";
import axios from "axios";
import VueAxios from "vue-axios";
import Shimmer from "vue3-shimmer";
import VueTagsInput from "@sipec/vue3-tags-input";
import { i18n } from "@/config";


const app = createApp(App)
  .use(router)
  .use(VueTagsInput)
  .use(Shimmer)
  .use(createPinia())
  .use(VueAxios, axios)
  .use(i18n);
globalComponents(app);
utils(app);

app.mount("#app");
